@import '../../_colours';

body {
	margin: 0;
}

.leaflet-container {
	width: 100%;
	height: 500px;
}

.circle {
	display: inline-block;
	width: 0.8rem;
	height: 0.8rem;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border: none;
	margin-top: 0.25rem;
	margin-bottom: 0;
	box-shadow: 0 0 0.12rem #333;

	&:hover {
		cursor: pointer;
	}

	&.active {
		background-color: $savant-smelter-hot;
	}
	&.inactive {
		background-color: $savant-smelter-cold;
	}
	&.shutdown {
		background-color: $savant-smelter-identify;
	}
	&.unknown {
		background-color: $savant-smelter-unknown;
	}
	&.highlight {
		width: 1.4rem;
		height: 1.4rem;
		border-style: solid;
		border-width: 4px;
		border-color: $earthi-vivid-green;
		outline: none;
		box-shadow: 0 0 20px #9ecaed;
	}
	&.blink {
		-webkit-animation: up-right 1s infinite;
		-moz-animation: up-right 1s infinite;
		-o-animation: up-right 1s infinite;
		animation: up-right 1s infinite;
	}
}

.mapboxgl-popup-content {
	padding: 0;
}

.mapboxgl-popup-tip {
	background: transparent;
	display: none;
}
.marker-active {
	border-color: $earthi-vivid-green;
	border-style: dashed;
	border-width: 3px;
	padding: 2px 5px;
}

.marker-inactive {
	border: none;
}

#menu {
	position: absolute;
	top: 10px;
	right: 10px;
	// background: #efefef;
	// padding: 10px;
	// font-family: 'Open Sans', sans-serif;
}
