.btn-outline-secondary.light-active-border:not(:first-of-type):not(
		:disabled
	):not(.disabled).active,
.btn-outline-secondary.light-active-border:not(:first-of-type):not(
		:disabled
	):not(.disabled):hover {
	border-left-color: lightgrey;
}

.smoothing-select {
	.form-control {
		width: 180px;
		display: inline-block;
	}
}
