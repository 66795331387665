@import '../../../_colours';

.btn-sm.modeButton {
	padding: 8px 12px 9px 12px;
	border-radius: 0.25rem;
}

.map-container {
	height: 500px;
	overflow-y: hidden;

	.map-left-panel {
		border-left: 1px solid $earthi-space-grey;
		border-top: 1px solid $earthi-space-grey;
		border-top-left-radius: 0.25rem;
		height: 500px;
		//added for GAINS
		border-bottom: 1px solid $earthi-light-grey;

		.smelter-list {
			.flex-row {
				display: flex;
				padding: 0.25rem 0.25rem 0 0.25rem;
				margin-bottom: 0.5rem;
				border-bottom: 1px solid $earthi-mid-grey;

				.flex-col {
					color: $earthi-vivid-green;

					&:first-of-type {
						width: 25px;
						font-size: 0.8rem;
					}
					&:nth-of-type(2) {
						flex: 1;
						font-size: 1.3rem;
						font-weight: 700;
					}
					&:last-of-type {
						width: 55px;
						text-align: center;
						font-size: 1rem;
						font-weight: 700;
						padding-top: 6px;
					}

					@media only screen and (max-width: 767px) {
						&:first-of-type {
							font-size: 0.6rem;
						}

						&:nth-of-type(2) {
							font-size: 1rem;
						}
						&:last-of-type {
							font-size: 0.8rem;
							padding-top: 4px;
						}
					}
				}
			}

			.tb-wrapper {
				min-height: 100%;
				height: 440px;
				display: flex;
				flex-direction: column;

				@media only screen and (min-width: 1600px) {
					height: 635px;
				}

				.table-body {
					flex: 0 auto;
					// height: 100vh;
					overflow-y: auto;
					overflow-x: hidden;
					//currently only supported on Firefox
					scrollbar-width: none;
					// Hide Scrollbar in Edge, IE 10/11
					-ms-overflow-style: none;

					// Hide Scrollbar in Chrome, Safari
					&::-webkit-scrollbar {
						width: 0;
					}

					.no-monitor {
						padding-top: 20px;
						text-align: center;
					}

					a {
						text-decoration: none;
						color: inherit;
					}

					.flex-row-cells {
						display: flex;
						flex-flow: row nowrap;
						font-size: 80%;
						font-weight: 400;
						padding: 0.25rem;
						// &:last-of-type {
						// 	padding-bottom: 10px;
						// }

						.flex-cell {
							white-space: nowrap;

							&:first-of-type {
								width: 25px;
								.disabled {
									color: $earthi-light-grey;
									cursor: not-allowed;
									pointer-events: none;
								}
							}

							&:nth-of-type(2) {
								flex: 1;
								display: flex;
								min-width: 0;
								.title-base {
									text-overflow: ellipsis;
									white-space: nowrap;
									overflow: hidden;
								}
								.title-small {
									font-size: 75%;
									padding-top: 2px;
								}
								.asterisk {
									flex-shrink: 0;
									font-size: 1.1rem;
									line-height: 1rem;
									padding-left: 2px;
								}
							}
							&:last-of-type {
								width: 55px;
								font-size: 0.6rem;
								font-weight: 500;
								display: flex;
								justify-content: space-around;
								align-items: center;
								.e-days {
									width: 18px;
									display: flex;
									justify-content: flex-end;
								}
							}
						}
					}
				}

				.table-notes {
					position: relative;
					border-top: 1px solid $earthi-light-grey;
					flex: 1 0 auto;

					.asterisk {
						font-size: 0.9rem;
						font-weight: bold;
					}

					&:before {
						position: absolute;
						content: '';
						top: -16px;

						display: block;

						width: 100%;
						height: 15px;

						background-image: linear-gradient(
							to top,
							rgba(255, 255, 255, 0.8) 0px,
							rgba(255, 255, 255, 0) 20px
						);
					}
				}
			}
		}
		@media only screen and (min-width: 1600px) {
			height: 703px;
		}
	}

	.no-map {
		background-color: $earthi-space-grey;
		height: 500px;
		color: $earthi-mid-grey;
		padding-top: 180px;
	}

	.map-viewer {
		.zoom-label {
			position: absolute;
			width: 50%;
			height: 20px;
			bottom: 0;
			right: 7px;
			text-align: right;
			color: white;
			text-shadow: 0 0 3px black;
		}

		.cesium-viewer {
			.cesium-widget canvas {
				border-top-right-radius: 0.25rem;
				height: 500px;
				@media only screen and (min-width: 1600px) {
					height: 703px;
				}
			}

			.cesium-widget-credits {
				font-size: 0.8rem;
				text-shadow: 0 0 3px black;
				padding-left: 2px;

				.cesium-credit-logoContainer *,
				.cesium-credit-textContainer * {
					display: none;
				}
			}
		}

		.map-feeback {
			position: absolute;
			top: 6px;
			right: 7px;
			opacity: 0.8;
		}
	}

	&.all-map {
		.map-left-panel {
			border-bottom-left-radius: 0.25rem;
		}
		.map-viewer {
			.cesium-viewer {
				.cesium-widget canvas {
					border-top-right-radius: 0.25rem;
					border-bottom-right-radius: 0.25rem;
				}
			}
		}
	}
	@media only screen and (min-width: 1600px) {
		height: 703px;
	}
}

.cesium-infoBox {
	.cesium-infoBox-title {
		color: $earthi-vivid-green;
		text-align: left;
		padding-left: 10px;
	}
	button.cesium-infoBox-camera {
		display: none;
	}
	.cesium-infoBox-description {
		padding: 0 10px;
	}
}

.cesium-viewer-selectionIndicatorContainer .cesium-selection-wrapper svg {
	fill: $earthi-vivid-green;
	stroke: $earthi-space-grey;
	stroke-width: 0.04rem;
}

.map-legend {
	border-left: 1px solid $earthi-space-grey;
	border-right: 1px solid $earthi-space-grey;
	border-bottom: 1px solid $earthi-space-grey;
	border-bottom-right-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
	border-top: 1px solid $earthi-mid-grey;
}

.circle {
	display: inline-block;
	width: 0.8rem;
	height: 0.8rem;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	border: none;
	margin-top: 0.25rem;
	margin-bottom: 0;
	box-shadow: 0 0 0.12rem #333;

	&.active {
		background-color: $savant-smelter-hot;
	}
	&.inactive {
		background-color: $savant-smelter-cold;
	}
	&.shutdown {
		background-color: $savant-smelter-identify;
	}
	&.unknown {
		background-color: $savant-smelter-unknown;
	}
}

.dropdown-menu.map-options-menu {
	min-width: 14rem;

	.type-blob {
		line-height: 1.5rem;
		opacity: 0.5;
		text-shadow: 1px 1px 3px black;
	}

	.map-option-filter-all {
		i {
			margin-right: 7px;
		}

		&.hidden-filter-option {
			display: none;
		}
	}
}

.pulsate i {
	-webkit-animation: pulsate 1s ease-out;
	-webkit-animation-iteration-count: infinite;
	opacity: 1;
}
@-webkit-keyframes pulsate {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
