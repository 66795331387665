$earthi-vivid-green: #75b626;
$earthi-vivid-green-alpha: rgba(117, 182, 38, 0.1);
$earthi-grass-green: #2d9147;
$earthi-sea-green: #426862;

$earthi-black: #000;
$earthi-space-grey: #565656;
$earthi-mid-grey: #9c9c9c;
$earthi-light-grey: #c5c6c6;
$earthi-white: #fff;

$earthi-deep-sea: #133042;

$savant-copper: #bb603d;
$savant-gold: #bf9a5c;
$savant-silver: #c7cccf;
$savant-zinc: #aebcc5;

$savant-smelter-hot: #ffdc00;
$savant-smelter-cold: #0074d9;
$savant-smelter-unknown: $savant-silver;
$savant-smelter-identify: #ff4136;
$savant-smelter-identify-alpha: rgba(255, 65, 54, 0.1);

:export {
	earthiVividGreen: $earthi-vivid-green;
	earthiVividGreenAlpha: $earthi-vivid-green-alpha;
	earthiGrassGreen: $earthi-grass-green;
	earthiSeaGreen: $earthi-sea-green;

	earthiBlack: $earthi-black;
	earthiSpaceGrey: $earthi-space-grey;
	earthiMidGrey: $earthi-mid-grey;
	earthiLightGrey: $earthi-light-grey;
	earthiWhite: $earthi-white;

	earthiDeepSea: $earthi-deep-sea;

	savantCopper: $savant-copper;
	savantGold: $savant-gold;
	savantSilver: $savant-silver;
	savantZinc: $savant-zinc;

	savantSmelterHot: $savant-smelter-hot;
	savantSmelterCold: $savant-smelter-cold;
	savantSmelterUnknown: $savant-smelter-unknown;
	savantSmelterIdentify: $savant-smelter-identify;
	savantSmelterIdentifyAlpha: $savant-smelter-identify-alpha;
}
