@import '../_colours';

.stripPlot {
	svg {
		border-radius: 0.2rem;
		background-color: #fafafa;

		rect.stripPlotLine {
			fill: $savant-smelter-unknown;
			stroke-width: 0px;
			cursor: crosshair;

			&.on {
				fill: $savant-smelter-hot;
			}
			&.off {
				fill: $savant-smelter-cold;
			}
		}

		text.stripPlotText {
			font-size: 0.7rem;
			font-weight: bold;
			display: none;
			z-index: -1;
		}

		.axis {
			path,
			line {
				stroke: $earthi-mid-grey;
			}
			text {
				font-size: 0.75rem;
			}

			&-bottom text {
				transform: rotate(45deg) translate(0, 4px);
				text-anchor: start;
			}
		}
	}
}
